html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.uploadcare--widget,
.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}